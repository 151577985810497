import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly6B2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/class-assembly/6B/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/class-assembly/6B/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/class-assembly/6B/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/class-assembly/6B/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/class-assembly/6B/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/class-assembly/6B/6.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },

        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    POWER OF WORDS
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    CLASS: 6 B	           DATE: 10, 11 and 12 February 2025

                                </Typography>
                                {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Imagination is the beginning of creation. You imagine what you desire, you will what you imagine and at last,
   you create what you will"- George Bernard Shaw

<br/>
        </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The class assembly by students of 6 B was held over three vibrant days. It was a dynamic blend of learning, creativity and interactive experiences, leaving a lasting impact on all who attended.
                                    <br></br>
                                    The first day commenced with an insightful introduction to ‘The Power of Words’, followed by an engaging activity, Tug of Words. This interactive exercise highlighted the strength of positive and uplifting words over negativity, reinforcing how words can either build confidence or diminish it.
                                    <br></br>
                                    On the second day, students explored powerful narratives from The Mahabharata, illustrating how words shape relationships. The contrasting tales of Drupada and Dronacharya, whose pride and resentment led to enmity and Krishna and Sudama, whose humility and warmth deepened their bond, served as a testament to the profound impact of words on human connections.
                                    <br></br>
                                    The final day featured an inspiring ramp walk, where students portrayed influential historical and contemporary figures. Through their impactful words, they demonstrated how language has the power to spark revolutions, inspire change and influence generations.
                                    <br></br>
                                    The Principal, applauded the students for their enthusiasm, teamwork and dedication. She appreciated their efforts in making the assembly a grand success. The lively performances and cheerful atmosphere ensured an enriching experience for both participants and audience. It indeed, created cherished memories for all.
                                    <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                Words shape worlds—choose them wisely.

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <ClassAssembly2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>
                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ClassAssembly6B2024;